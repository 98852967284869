import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../css/dashboard.css";

const Dashboard = () => {
  const [totalDetections, setTotalDetections] = useState("000");
  const [cpuUsage, setCpuUsage] = useState("000");
  const [ramUsage, setRamUsage] = useState("000");
  const [registeredGames, setRegisteredGames] = useState([]);
  const [recentBuilds, setRecentBuilds] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    checkAuthentication();
    if (isAuthenticated) {
      fetchRegisteredGames();
      fetchRecentBuilds();
      fetchTotalDetections();
      fetchCpuUsage();
      fetchRamUsage();
    }
  }, [isAuthenticated]);

  const checkAuthentication = async () => {
    try {
      const url =
        process.env.NODE_ENV === "production"
          ? "https://dashboardapi.skyforceanticheat.com"
          : "http://localhost:3001";
      const response = await axios.get(url + "/api/auth/check", {
        withCredentials: true,
      });
      if (response.data.isAuthenticated) {
        setIsAuthenticated(true);
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.error("Failed to check authentication:", error);
      navigate("/login");
    }
  };

  const fetchRegisteredGames = async () => {
    try {
      const url =
        process.env.NODE_ENV === "production"
          ? "https://dashboardapi.skyforceanticheat.com"
          : "http://localhost:3001";
      const response = await axios.get(url + "/api/registered-games", {
        withCredentials: true,
      });
      setRegisteredGames(response.data);
    } catch (error) {
      console.error("Error fetching registered games:", error);
    }
  };

  const fetchRecentBuilds = async () => {
    try {
      const url =
        process.env.NODE_ENV === "production"
          ? "https://dashboardapi.skyforceanticheat.com"
          : "http://localhost:3001";
      const response = await axios.get(
        url + "/api/builds/recent?page=1&size=8",
        { withCredentials: true },
      );
      setRecentBuilds(response.data);
    } catch (error) {
      console.error("Error fetching recent builds:", error);
    }
  };

  const fetchTotalDetections = async () => {
    try {
      const url =
        process.env.NODE_ENV === "production"
          ? "https://dashboardapi.skyforceanticheat.com"
          : "http://localhost:3001";
      const response = await axios.get(url + "/api/total-detections", {
        withCredentials: true,
      });
      setTotalDetections(response.data.count);
    } catch (error) {
      console.error("Failed to fetch total detections:", error);
    }
  };

  const fetchCpuUsage = async () => {
    try {
      const url =
        process.env.NODE_ENV === "production"
          ? "https://dashboardapi.skyforceanticheat.com"
          : "http://localhost:3001";
      const response = await axios.get(url + "/api/stats/cpu", {
        withCredentials: true,
      });
      setCpuUsage(response.data.cpuUsage[0].toFixed(2) + "%");
    } catch (error) {
      console.error("Failed to fetch CPU usage:", error);
    }
  };

  const fetchRamUsage = async () => {
    try {
      const url =
        process.env.NODE_ENV === "production"
          ? "https://dashboardapi.skyforceanticheat.com"
          : "http://localhost:3001";
      const response = await axios.get(url + "/api/stats/ram", {
        withCredentials: true,
      });
      const usedHeap = (response.data.ramUsage.heapUsed / 1024 / 1024).toFixed(
        2,
      );
      setRamUsage(`${usedHeap} MB`);
    } catch (error) {
      console.error("Failed to fetch RAM usage:", error);
    }
  };

  return (
    <div className="page-content">
      <section className="dashboard" id="dashboard-section">
        <div className="text dashboard-header">Dashboard</div>

        <div className="home-content">
          <div className="overview-boxes" id="total-detections-box">
            <div className="box">
              <div className="right-side">
                <div className="box-topic">Total Detections</div>
                <div className="number" id="total-detections">
                  {totalDetections}
                </div>
              </div>
            </div>
            <div className="box" id="cpu-usage-box">
              <div className="right-side">
                <div className="box-topic">Server CPU Load</div>
                <div className="number" id="cpu-usage">
                  {cpuUsage}
                </div>
              </div>
            </div>
            <div className="box" id="ram-usage-box">
              <div className="right-side">
                <div className="box-topic">Server RAM Load</div>
                <div className="number" id="ram-usage">
                  {ramUsage}
                </div>
              </div>
            </div>
            <div className="box">
              <div className="right-side">
                <div className="box-topic">Last Update</div>
                <div className="number">March 17, 2024</div>
              </div>
            </div>
          </div>

          <div className="sales-boxes">
            <div className="recent-builds box">
              <div className="title">Recent Builds</div>
              <div className="builds-details">
                <ul className="details">
                  <li className="topic">Build ID</li>
                  {recentBuilds.map((build) => (
                    <li key={build.buildID}>{build.buildID}</li>
                  ))}
                </ul>
                <ul className="details">
                  <li className="topic">Date</li>
                  {recentBuilds.map((build) => (
                    <li key={build.buildID}>
                      {new Date(build.date).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      })}
                    </li>
                  ))}
                </ul>
                <ul className="details">
                  <li className="topic">Package Name</li>
                  {recentBuilds.map((build) => (
                    <li key={build.buildID}>{build.packageName}</li>
                  ))}
                </ul>
              </div>
              <div className="button">
                <a href="/history">See All</a>
              </div>
            </div>

            <div className="top-sales box">
              <div className="title">Registered Games</div>
              <ul className="top-sales-details">
                {registeredGames.map((game) => (
                  <li key={game.GameID}>
                    <span className="product">{game.PackageName}</span>
                    <span className="price">(ID: {game.GameID})</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
