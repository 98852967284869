import React from "react";
import { Link } from "react-router-dom";
import "../../css/builder/sidebar.css";

const BuilderSidebar = ({ currentStep, onStepClick }) => {
  const stepClasses = (step) => {
    return currentStep >= step ? "active" : "inactive";
  };

  return (
    <aside className="builder-sidebar">
      <div className="aside-title">Skyforce Builder</div>
      <Link to="/dashboard" className="back-button-aside">
        Back
      </Link>
      <ul className="aside-list">
        <li className="aside-list-item" onClick={() => onStepClick(1)}>
          <div className={`aside-list-item-number ${stepClasses(1)}`}>1</div>
          <div className="aside-list-item-content">
            <h4>Upload</h4>
            <p>Upload the app</p>
          </div>
        </li>
        <li className="aside-list-item" onClick={() => onStepClick(2)}>
          <div className={`aside-list-item-number ${stepClasses(2)}`}>2</div>
          <div className="aside-list-item-content">
            <h4>Protections</h4>
            <p>Select detections</p>
          </div>
        </li>
        <li className="aside-list-item" onClick={() => onStepClick(3)}>
          <div className={`aside-list-item-number ${stepClasses(3)}`}>3</div>
          <div className="aside-list-item-content">
            <h4>Extras</h4>
            <p>Select extras</p>
          </div>
        </li>
        <li className="aside-list-item" onClick={() => onStepClick(4)}>
          <div className={`aside-list-item-number ${stepClasses(4)}`}>4</div>
          <div className="aside-list-item-content">
            <h4>Processing</h4>
            <p>Rebuild the app</p>
          </div>
        </li>
        <li className="aside-list-item" onClick={() => onStepClick(5)}>
          <div className={`aside-list-item-number ${stepClasses(5)}`}>5</div>
          <div className="aside-list-item-content">
            <h4>Sign</h4>
            <p>Sign the app</p>
          </div>
        </li>
        <li className="aside-list-item" onClick={() => onStepClick(6)}>
          <div className={`aside-list-item-number ${stepClasses(6)}`}>6</div>
          <div className="aside-list-item-content">
            <h4>Download</h4>
            <p>Download the build</p>
          </div>
        </li>
      </ul>
    </aside>
  );
};

export default BuilderSidebar;
