import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ProgressBar from "../../components/progressbar";
import "../../css/builder/upload.css";

const Upload = ({ onNext }) => {
  const [fileName, setFileName] = useState("");
  const [appName, setAppName] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [error, setError] = useState("");
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      setFileName(event.target.files[0].name);
      setError("");
    }
  };

  const handleUploadClick = () => {
    document.getElementById("app-upload").click();
  };

  const handleNextClick = async () => {
    if (appName && fileName && isConfirmed) {
      setError("");
      if (!uploading) {
        setUploading(true);
        const formData = new FormData();
        const fileInput = document.getElementById("app-upload");
        if (fileInput.files.length === 0) {
          setError("No file selected.");
          setUploading(false);
          return;
        }
        formData.append("file", fileInput.files[0]);
        formData.append("appName", appName);

        try {
          const url =
            process.env.NODE_ENV === "production"
              ? "https://dashboardapi.skyforceanticheat.com"
              : "http://localhost:3001";
          const response = await axios.post(
            url + "/api/builder/upload",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: (progressEvent) => {
                setProgress(
                  Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total,
                  ),
                );
              },
            },
          );

          if (response.status === 200) {
            const data = response.data;
            setUploading(false);
            setProgress(100);
            onNext();
            navigate(`/builder/protections?uniqueId=${data.uniqueId}`);
          } else {
            setError(response.data.message || "Failed to upload the file.");
            setUploading(false);
          }
        } catch (error) {
          setError("An error occurred during the upload.");
          setUploading(false);
        }
      }
    } else {
      setError("Please fill in all required fields.");
    }
  };

  return (
    <main className="form-container">
      <div className="form-icon">
        <i className="fas fa-cloud-upload-alt"></i>
      </div>
      <h2 className="form-title">Upload App</h2>
      <p className="form-description">
        Drag an apk or aab file here or{" "}
        <span className="upload-browse" onClick={handleUploadClick}>
          Browse
        </span>
      </p>
      <form>
        <div className="form-group">
          <label htmlFor="app-name">App Name</label>
          <input
            type="text"
            id="app-name"
            placeholder="e.g. MyApp"
            value={appName}
            onChange={(e) => setAppName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="app-upload">Upload App</label>
          <div
            className="upload-area"
            id="upload-area"
            onClick={handleUploadClick}
          >
            <i className="fas fa-plus upload-icon"></i>
            <div className="upload-text">{fileName || "Upload App"}</div>
            <div className="upload-browse">
              Drag an apk or aab file here or <span>Browse</span>
            </div>
            <input
              type="file"
              id="app-upload"
              accept=".apk,.aab"
              onChange={handleFileChange}
              required
            />
          </div>
        </div>
        {uploading && <ProgressBar progress={progress} />}
        <div className="form-confirm">
          <input
            type="checkbox"
            id="confirm"
            checked={isConfirmed}
            onChange={(e) => setIsConfirmed(e.target.checked)}
            required
          />
          <label htmlFor="confirm">
            I confirm that I am authorized to modify this app.
          </label>
        </div>
        {error && <p className="error-message">{error}</p>}
        <div className="form-buttons">
          <button
            type="button"
            className="next-button"
            onClick={handleNextClick}
            disabled={uploading || !appName || !fileName || !isConfirmed}
          >
            {uploading ? "Uploading..." : "Next Step"}
          </button>
        </div>
      </form>
    </main>
  );
};

export default Upload;
