import React, { useEffect, useState } from "react";
import axios from "axios";
import "../css/detections.css";

const Detections = () => {
  const [reports, setReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchReports();
  }, [currentPage, pageSize, searchQuery]);

  const fetchReports = async () => {
    try {
      const url =
        process.env.NODE_ENV === "production"
          ? "https://dashboardapi.skyforceanticheat.com"
          : "http://localhost:3001";
      const response = await axios.get(
        url +
          `/auth/reports?page=${currentPage}&size=${pageSize}&search=${searchQuery}`,
        { withCredentials: true },
      );
      setReports(response.data);
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentPage(1); // Reset to first page when page size changes
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page when starting a new search
  };

  return (
    <div className="page-content">
      <section className="detections" id="detections-section">
        <div className="reports-container">
          <h2 className="recent-detections-header">Recent Detections</h2>
          <div className="overflow-x-auto">
            <table>
              <thead>
                <tr>
                  <th>Detection ID</th>
                  <th>Detection Number</th>
                  <th>Detection Name</th>
                  <th>Internal Detection</th>
                  <th>Detection Time</th>
                  <th>Game</th>
                </tr>
              </thead>
              <tbody id="reports-table-body">
                {reports.map((report) => (
                  <tr key={report.DetectionID}>
                    <td>{report.DetectionID}</td>
                    <td>{report.DetectionNumber}</td>
                    <td>{report.DetectionName}</td>
                    <td>{JSON.parse(report.Details).name}</td>
                    <td>
                      {new Date(report.DetectionTime).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      })}
                    </td>
                    <td>{report.PackageName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="controls">
          <button onClick={handlePrevPage} disabled={currentPage === 1}>
            Previous Page
          </button>
          <input
            type="text"
            id="searchBox"
            placeholder="Search by Detection ID..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <select
            id="pageSize"
            value={pageSize}
            onChange={handlePageSizeChange}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30" selected>
              30
            </option>
            <option value="50">50</option>
          </select>
          <button onClick={handleNextPage}>Next Page</button>
        </div>
      </section>
    </div>
  );
};

export default Detections;
