import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import "../../css/builder/extras.css";

const extrasData = [
  {
    title: "Class Protection",
    internalName: "javaCodeObfuscation",
    description: "Please add the class name that you want to protect.",
    icon: "fas fa-cogs",
    inputs: ["Class name for protection"],
    caution:
      "CAUTION: Add only the class you created yourself. (Exclude external libraries and SDKs)",
    items: [],
  },
  {
    title: "String Encryption",
    internalName: "stringEncryption",
    description: "Encrypts strings in the application.",
    icon: "fas fa-tools",
    inputs: ["Class name for string encryption"],
    caution:
      "CAUTION: Add only the class you created yourself. (Exclude external libraries and SDKs)",
    items: [],
  },
  {
    title: "String Virtualization",
    internalName: "virtualizeJavaStrings",
    description:
      "Virtualizes strings in the application through a virtual machine.",
    inputs: ["Class name for string virtualization"],
    caution:
      "CAUTION: This may have a negative impact on the performance of the application.",
    icon: "fas fa-shield-alt",
    items: [],
  },
  {
    title: "Native Library Encryption",
    internalName: "nativeLibraryEncryption",
    description: "Protects the native libraries used in the application.",
    icon: "fas fa-lock",
    inputs: ["Names of native library that should be protected"],
    caution:
      "CAUTION: This may slow down launch time of the application. Only use it on critical libraries.\nThis option will make the app unable to work on emulators. To support emulators, please build the app for x86/x86_64 architecture.\nIt is recommended to add libil2cpp.so for unity games to also prevent il2cpp dumping.",
    items: [],
  },
];

const Extras = ({ onNext }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const uniqueId = params.get("uniqueId");

  const initializeFormValues = () => {
    return extrasData.reduce((acc, extra) => {
      acc[extra.title] = {
        items: extra.items || [],
        selectedItem: "",
      };
      extra.inputs.forEach((input) => {
        acc[extra.title][input] = "";
      });
      return acc;
    }, {});
  };

  const [formValues, setFormValues] = useState(initializeFormValues());

  useEffect(() => {
    const savedFormValues = Cookies.get("extrasFormValues");
    if (savedFormValues) {
      const parsedFormValues = JSON.parse(savedFormValues);
      const mergedFormValues = { ...initializeFormValues() };

      extrasData.forEach((extra) => {
        if (
          parsedFormValues[extra.title] &&
          parsedFormValues[extra.title].items
        ) {
          mergedFormValues[extra.title].items =
            parsedFormValues[extra.title].items;
        }
      });

      setFormValues(mergedFormValues);
    }
  }, []);

  const handleInputChange = (title, input, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [title]: {
        ...prevValues[title],
        [input]: value,
      },
    }));
  };

  const handleAddItem = (title, input) => {
    const newItem = formValues[title][input].trim();
    if (newItem && !formValues[title].items.includes(newItem)) {
      setFormValues((prevValues) => ({
        ...prevValues,
        [title]: {
          ...prevValues[title],
          items: [...prevValues[title].items, newItem],
          [input]: "",
        },
      }));
    }
  };

  const handleRemoveItem = (title) => {
    const selectedItem = formValues[title].selectedItem;
    if (selectedItem) {
      setFormValues((prevValues) => ({
        ...prevValues,
        [title]: {
          ...prevValues[title],
          items: prevValues[title].items.filter((i) => i !== selectedItem),
          selectedItem: "",
        },
      }));
    }
  };

  const handleSelectItem = (title, item) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [title]: {
        ...prevValues[title],
        selectedItem: item,
      },
    }));
  };

  const handleSave = () => {
    Cookies.set("extrasFormValues", JSON.stringify(formValues), {
      expires: 365,
    });
    alert("Data saved successfully!");
  };

  const handleNextClick = async () => {
    const extras = extrasData.map((extra) => ({
      internalName: extra.internalName,
      items: formValues[extra.title].items,
    }));

    try {
      const url =
        process.env.NODE_ENV === "production"
          ? "https://dashboardapi.skyforceanticheat.com"
          : "http://localhost:3001";
      const response = await axios.post(
        url + "/api/builder/start-processing",
        {
          uniqueId,
          extras,
        },
        {
          withCredentials: true,
        },
      );

      if (response.status === 200) {
        onNext();
        navigate(`/builder/processing?uniqueId=${uniqueId}`);
      } else {
        console.error("Failed to send data to the server:", response);
      }
    } catch (error) {
      console.error("Error sending data to the server:", error);
    }
  };

  return (
    <main className="extras-container">
      <div className="extras-header">
        <div className="form-icon">
          <i className="fas fa-plus"></i>
        </div>
        <h2 className="form-title">Extras</h2>
        <p className="form-description">
          Select additional protections to include in your app
        </p>
      </div>
      {extrasData.map((extra, index) => (
        <div className="extras-section" key={index}>
          <div className="header">
            <div className="title">
              <i className={extra.icon}></i>
              <span>{extra.title}</span>
            </div>
          </div>
          <div className="content">
            <p>{extra.description}</p>
            {extra.caution && <p className="caution">{extra.caution}</p>}
            {extra.inputs.length > 0 && (
              <div className="inputs-container">
                {extra.inputs.map((input, idx) => (
                  <div className="input-group" key={idx}>
                    <input
                      type="text"
                      placeholder={input}
                      value={formValues[extra.title][input]}
                      onChange={(e) =>
                        handleInputChange(extra.title, input, e.target.value)
                      }
                    />
                    <button
                      type="button"
                      className="add-button"
                      onClick={() => handleAddItem(extra.title, input)}
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      className="remove-button"
                      onClick={() => handleRemoveItem(extra.title)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <div className="items-list-box-container">
                  <div
                    className={`items-list-box ${formValues[extra.title].items.length === 0 ? "empty" : ""}`}
                  >
                    <ul className="items-list">
                      {formValues[extra.title].items.map((item, idx) => (
                        <li
                          key={idx}
                          className={`item ${
                            formValues[extra.title].selectedItem === item
                              ? "selected"
                              : ""
                          }`}
                          onClick={() => handleSelectItem(extra.title, item)}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
      <div className="extras-buttons">
        <button type="button" className="save-button" onClick={handleSave}>
          Save
        </button>
        <button type="button" className="next-button" onClick={handleNextClick}>
          Next Step
        </button>
      </div>
    </main>
  );
};

export default Extras;
