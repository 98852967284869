import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import "../css/login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLoginClick = async () => {
    if (email && password) {
      setError("");

      try {
        const url =
          process.env.NODE_ENV === "production"
            ? "https://dashboardapi.skyforceanticheat.com"
            : "http://localhost:3001";
        const response = await axios.post(
          url + "/auth/login",
          {
            email,
            password,
          },
          {
            withCredentials: true, // This ensures cookies are sent with requests
          },
        );

        if (response.status === 200) {
          navigate("/dashboard");
        } else {
          setError("Login failed. Please check your email and password.");
        }
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 401) {
          setError("Account not activated or incorrect credentials.");
        } else {
          setError("Login failed. Please try again.");
        }
      }
    } else {
      setError("Please fill in all required fields.");
    }
  };

  return (
    <div className="login-page">
      <main className="login-container">
        <div className="login-header">
          <div className="form-icon">
            <i className="fas fa-sign-in-alt"></i>
          </div>
          <h2 className="form-title">Login</h2>
          <p className="form-description">Sign in to your account</p>
        </div>
        <form>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <div className="form-buttons">
            <button
              type="button"
              className="login-button"
              onClick={handleLoginClick}
            >
              Login
            </button>
          </div>
        </form>
        <div className="additional-links">
          <div className="forgot-password-link">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
          <div className="signup-link">
            Don't have an account?{" "}
            <Link className="signup-link-signup" to="/register">
              Sign Up
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Login;
