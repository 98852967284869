import React from "react";
import "../css/progressbar.css";

const ProgressBar = ({ progress }) => {
  return (
    <div className="progress-bar">
      <div
        className="progress"
        style={{ width: `${progress}%`, backgroundColor: "#2563eb" }}
      ></div>
      <span
        className="progress-bar-text"
        style={{ color: progress > 50 ? "#fff" : "#000" }}
      >
        {progress}%
      </span>
    </div>
  );
};

export default ProgressBar;
