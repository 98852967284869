import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import "../../css/builder/protections.css";

import Item from "../../components/builder/item";
import Cookies from "js-cookie";
import axios from "axios";

const protectionsData = [
  {
    title: "Default Protection",
    items: [
      {
        name: "Hooking Detection",
        originalName: "hooking",
        tooltip: "Detects hooking frameworks like Xposed",
        defaultChecked: true,
        disabled: true,
        defaultInfo: "This item is part of the default protection",
        handlingType: "Show Toast",
        possibleHandlingTypes: ["Show Toast"],
      },
      {
        name: "Frida Detection",
        originalName: "frida",
        tooltip: "Detects the presence of Frida tools",
        defaultChecked: true,
        disabled: true,
        defaultInfo: "This item is part of the default protection",
        handlingType: "Show Toast",
        possibleHandlingTypes: ["Show Toast"],
      },
      {
        name: "Injection Detection",
        originalName: "injection",
        tooltip: "Detects code injection attempts",
        defaultChecked: true,
        disabled: true,
        defaultInfo: "This item is part of the default protection",
        handlingType: "Show Toast",
        possibleHandlingTypes: ["Show Toast"],
      },
      {
        name: "Debugger Detection",
        originalName: "debugger",
        tooltip: "Detects active debuggers",
        defaultChecked: true,
        disabled: true,
        defaultInfo: "This item is part of the default protection",
        handlingType: "Show Toast",
        possibleHandlingTypes: ["Show Toast"],
      },
      {
        name: "Tracer Detection",
        originalName: "tracerDetection",
        tooltip: "Detects tracing tools",
        defaultChecked: true,
        disabled: true,
        defaultInfo: "This item is part of the default protection",
        handlingType: "Show Toast",
        possibleHandlingTypes: ["Show Toast"],
      },
      {
        name: "Detect Memory Dump",
        originalName: "memoryDump",
        tooltip: "Detects memory dump tools",
        defaultChecked: true,
        disabled: true,
        defaultInfo: "This item is part of the default protection",
        handlingType: "Show Toast",
        possibleHandlingTypes: ["Show Toast"],
      },
    ],
  },
  {
    title: "System Modifications",
    items: [
      {
        name: "SU Detection",
        originalName: "suBinary",
        tooltip: "Detects the presence of SU binary",
        handlingType: "Show Toast",
        possibleHandlingTypes: [
          "Ignore",
          "Kill Process",
          "Show Toast",
          "Show Message Box",
        ],
      },
      {
        name: "App List Detection",
        originalName: "applist",
        tooltip: "Detects suspicious apps installed on the device",
        handlingType: "Show Toast",
        possibleHandlingTypes: [
          "Ignore",
          "Kill Process",
          "Show Toast",
          "Show Message Box",
        ],
      },
      {
        name: "SELinux Detection",
        originalName: "selinux",
        tooltip: "Detects SELinux status and configuration",
        handlingType: "Show Toast",
        possibleHandlingTypes: [
          "Ignore",
          "Kill Process",
          "Show Toast",
          "Show Message Box",
        ],
      },
      {
        name: "Custom ROM Detection",
        originalName: "customRom",
        tooltip: "Detects if a custom ROM is installed",
        handlingType: "Show Toast",
        possibleHandlingTypes: [
          "Ignore",
          "Kill Process",
          "Show Toast",
          "Show Message Box",
        ],
      },
      {
        name: "Bootloader Detection",
        originalName: "bootloader",
        tooltip: "Detects if the bootloader is unlocked",
        handlingType: "Show Toast",
        possibleHandlingTypes: [
          "Ignore",
          "Kill Process",
          "Show Toast",
          "Show Message Box",
        ],
      },
      {
        name: "Magisk Detection",
        originalName: "magisk",
        tooltip: "Detects Magisk and related modules",
        handlingType: "Show Toast",
        possibleHandlingTypes: [
          "Ignore",
          "Kill Process",
          "Show Toast",
          "Show Message Box",
        ],
      },
      {
        name: "System Read/Write Detection",
        originalName: "sysReadWrite",
        tooltip: "Detects if the system partition is writable",
        handlingType: "Show Toast",
        possibleHandlingTypes: [
          "Ignore",
          "Kill Process",
          "Show Toast",
          "Show Message Box",
        ],
      },
      {
        name: "KernelSU & Apatch Detection",
        originalName: "rootHide",
        tooltip: "Detects KernelSU and Apatch root management tools",
        handlingType: "Show Toast",
        possibleHandlingTypes: [
          "Ignore",
          "Kill Process",
          "Show Toast",
          "Show Message Box",
        ],
      },
    ],
  },
  {
    title: "Runtime Protection",
    items: [
      {
        name: "Memory Editing Tools Detection",
        originalName: "memoryEditing",
        tooltip: "Detects tools used for memory editing",
        handlingType: "Show Toast",
        possibleHandlingTypes: ["Ignore", "Kill Process", "Show Toast"],
      },
      {
        name: "Xposed Detection",
        originalName: "xposed",
        tooltip: "Detects Xposed framework",
        handlingType: "Show Toast",
        possibleHandlingTypes: ["Ignore", "Kill Process", "Show Toast"],
      },
      {
        name: "Speed Hack Detection",
        originalName: "speedHack",
        tooltip: "Detects speed hacking attempts",
        handlingType: "Kill Process",
        possibleHandlingTypes: ["Ignore", "Kill Process", "Show Toast"],
      },
      {
        name: "Runtime Checks",
        originalName: "runtimeCheck",
        tooltip: "Performs runtime checks for tampering",
        handlingType: "Kill Process",
        possibleHandlingTypes: ["Ignore", "Kill Process", "Show Toast"],
      },
      {
        name: "Unity Runtime Il2cpp Dump Prevention",
        originalName: "unityHookCheck",
        tooltip: "Prevents dumping of Unity Il2cpp at runtime",
        handlingType: "Ignore",
        possibleHandlingTypes: ["Ignore"],
      },
      {
        name: "Cheating App Detection",
        originalName: "cheatingApp",
        tooltip:
          "Detects cheating apps such as Game Guardian or Lucky Patcher (can be performance heavy)",
        handlingType: "Show Toast",
        possibleHandlingTypes: [
          "Ignore",
          "Kill Process",
          "Show Toast",
          "Show Message Box",
        ],
      },
    ],
  },
  {
    title: "Environment Control",
    items: [
      {
        name: "Block ADB",
        originalName: "adbEnabled",
        tooltip: "Blocks access via ADB",
        handlingType: "Show Toast",
        possibleHandlingTypes: [
          "Ignore",
          "Kill Process",
          "Show Toast",
          "Show Message Box",
        ],
      },
      {
        name: "Block Overlays",
        originalName: "overlayBlock",
        tooltip: "Blocks screen overlays",
        handlingType: "Ignore",
        possibleHandlingTypes: ["Ignore", "Ignore"],
      },
      {
        name: "Detect Auto Clicker",
        originalName: "autoClicker",
        tooltip: "Detects auto-clickers using accessibility services",
        handlingType: "Show Toast",
        possibleHandlingTypes: ["Ignore", "Kill Process", "Show Toast"],
      },
      {
        name: "Detect Developer Options",
        originalName: "developerOptions",
        tooltip: "Detects if developer options are enabled",
        handlingType: "Show Toast",
        possibleHandlingTypes: [
          "Ignore",
          "Kill Process",
          "Show Toast",
          "Show Message Box",
        ],
      },
      {
        name: "Detect Unknown Sources",
        originalName: "unknownSources",
        tooltip: "Detects apps from unknown sources",
        handlingType: "Show Toast",
        possibleHandlingTypes: [
          "Ignore",
          "Kill Process",
          "Show Toast",
          "Show Message Box",
        ],
      },
      {
        name: "VPN Detection",
        originalName: "isVPNConnected",
        tooltip: "Detects the use of VPN",
        handlingType: "Show Toast",
        possibleHandlingTypes: [
          "Ignore",
          "Kill Process",
          "Show Toast",
          "Show Message Box",
        ],
      },
      {
        name: "Fake GPS Detection",
        originalName: "isMockLocation",
        tooltip: "Detects the use of fake GPS apps",
        handlingType: "Show Toast",
        possibleHandlingTypes: [
          "Ignore",
          "Kill Process",
          "Show Toast",
          "Show Message Box",
        ],
      },
    ],
  },
  {
    title: "Debug Protection",
    items: [
      {
        name: "Game Guardian Detection",
        originalName: "gameGuardian",
        tooltip: "Detects Game Guardian app",
        handlingType: "Show Toast",
        possibleHandlingTypes: ["Ignore", "Kill Process", "Show Toast"],
      },
      {
        name: "App Debuggable Check",
        originalName: "isAppDebuggable",
        tooltip: "Checks if the app is debuggable",
        handlingType: "Show Toast",
        possibleHandlingTypes: ["Ignore", "Kill Process", "Show Toast"],
      },
      {
        name: "Java Debugger Detection",
        originalName: "javaDebugger",
        tooltip: "Detects Java debuggers attached to the app",
        handlingType: "Kill Process",
        possibleHandlingTypes: ["Ignore", "Kill Process", "Show Toast"],
      },
      {
        name: "Hardware Debug Points Detection",
        originalName: "hardwareBreakpoints",
        tooltip: "Detects hardware debug points",
        handlingType: "Kill Process",
        possibleHandlingTypes: ["Ignore", "Kill Process", "Show Toast"],
      },
    ],
  },
  {
    title: "Tampering Detection",
    items: [
      {
        name: "APK Files Checksum",
        originalName: "apkFilesChecksum",
        tooltip: "Validates the checksum of APK files",
        handlingType: "Kill Process",
        possibleHandlingTypes: ["Ignore", "Kill Process", "Show Toast"],
      },
      {
        name: "App Integrity Scan",
        originalName: "libraryIntegrity",
        tooltip: "Scans for app integrity issues",
        handlingType: "Kill Process",
        possibleHandlingTypes: ["Ignore", "Kill Process", "Show Toast"],
      },
      {
        name: "Prevent Code Patching",
        originalName: "selfIntegrity",
        tooltip: "Prevents patching of app code",
        handlingType: "Ignore",
        possibleHandlingTypes: ["Ignore", "Kill Process", "Show Toast"],
      },
      {
        name: "Prevent Repacking",
        originalName: "appRepackaged",
        tooltip: "Prevents repacking of the app",
        handlingType: "Show Toast",
        possibleHandlingTypes: ["Ignore", "Kill Process", "Show Toast"],
      },
    ],
  },
  {
    title: "App Encryption",
    items: [
      {
        name: "Virtualize Java Strings",
        originalName: "virtualizeJavaStrings",
        tooltip:
          "Virtualizes Java strings to prevent reverse engineering (possible performance impact)",
        handlingType: "Ignore",
        possibleHandlingTypes: ["Ignore"],
      },
      {
        name: "String Encryption",
        originalName: "stringEncryption",
        tooltip: "Encrypts strings to prevent reverse engineering",
        handlingType: "Ignore",
        possibleHandlingTypes: ["Ignore"],
      },
      {
        name: "Native Library Encryption",
        originalName: "nativeLibraryEncryption",
        tooltip: "Encrypts native libraries to prevent reverse engineering",
        handlingType: "Ignore",
        possibleHandlingTypes: ["Ignore"],
      },
      {
        name: "Java Code Obfuscation",
        originalName: "javaCodeObfuscation",
        tooltip: "Obfuscates Java code to make it harder to reverse engineer",
        handlingType: "Ignore",
        possibleHandlingTypes: ["Ignore"],
      },
    ],
  },
  {
    title: "Virtualization Detection",
    items: [
      {
        name: "Emulator Detection",
        originalName: "emulator",
        tooltip: "Detects if the app is running in an emulator",
        handlingType: "Show Toast",
        possibleHandlingTypes: [
          "Ignore",
          "Kill Process",
          "Show Toast",
          "Show Message Box",
        ],
      },
      {
        name: "Parallel App Detection",
        originalName: "virtualSpace",
        tooltip: "Detects apps running in parallel spaces",
        handlingType: "Show Toast",
        possibleHandlingTypes: [
          "Ignore",
          "Kill Process",
          "Show Toast",
          "Show Message Box",
        ],
      },
    ],
  },
  {
    title: "Client Communication",
    items: [
      {
        name: "MiTM Detection",
        originalName: "mitmDetection",
        tooltip: "Detects Man-in-the-Middle attack attempts",
        handlingType: "Show Toast",
        possibleHandlingTypes: ["Ignore", "Kill Process", "Show Toast"],
      },
    ],
  },
];

const Protections = ({ onNext }) => {
  const [checkedItems, setCheckedItems] = useState({});
  const [handlingTypes, setHandlingTypes] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const uniqueId = params.get("uniqueId");

  useEffect(() => {
    checkAuthentication();
  }, [isAuthenticated]);

  const checkAuthentication = async () => {
    try {
      const url =
        process.env.NODE_ENV === "production"
          ? "https://dashboardapi.skyforceanticheat.com"
          : "http://localhost:3001";
      const response = await axios.get(url + "/api/auth/check", {
        withCredentials: true,
      });
      if (response.data.isAuthenticated) {
        setIsAuthenticated(true);
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.error("Failed to check authentication:", error);
      navigate("/login");
    }
  };

  useEffect(() => {
    checkAuthentication();
    const savedProtections = Cookies.get("protections");
    const savedHandlingTypes = Cookies.get("handlingTypes");
    if (savedProtections) {
      setCheckedItems(JSON.parse(savedProtections));
    }
    if (savedHandlingTypes) {
      setHandlingTypes(JSON.parse(savedHandlingTypes));
    }

    handleToggle("Hooking Detection", true);
    // handleToggle("Frida Detection", true);
    handleToggle("Injection Detection", true);
    handleToggle("Debugger Detection", true);
    handleToggle("Tracer Detection", true);
  }, [isAuthenticated]);

  const handleToggle = (name, checked) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleHandlingTypeChange = (name, value) => {
    setHandlingTypes((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = () => {
    Cookies.set("protections", JSON.stringify(checkedItems), { expires: 365 });
    Cookies.set("handlingTypes", JSON.stringify(handlingTypes), {
      expires: 365,
    });
    alert("Protections and handling types saved!");
  };

  const handleNextClick = async () => {
    try {
      const protections = {};

      protectionsData.forEach((section) => {
        section.items.forEach((item) => {
          protections[item.originalName] = {
            enabled: checkedItems[item.name] || false,
            handlingType:
              handlingTypes[item.originalName] || item.handlingType || "Ignore",
          };
        });
      });

      const url =
        process.env.NODE_ENV === "production"
          ? "https://dashboardapi.skyforceanticheat.com"
          : "http://localhost:3001";
      const response = await axios.post(
        url + "/api/builder/upload-config",
        {
          uniqueId,
          protections,
        },
        {
          withCredentials: true,
        },
      );

      if (response.status === 200) {
        onNext();
        // navigate(`/builder/processing?uniqueId=${uniqueId}`);
        navigate(`/builder/extras?uniqueId=${uniqueId}`);
      } else {
        alert("Failed to start processing the apk, please contact support.");
      }
    } catch (error) {
      console.error("An error occurred while starting processing:", error);
      alert("An error occurred while starting processing.");
    }
  };

  return (
    <main className="protections-container">
      <div className="protections-header">
        <div className="form-icon">
          <i className="fas fa-shield-alt"></i>
        </div>
        <h2 className="form-title">Protections</h2>
        <p className="form-description">
          Select the protections you want to apply to the app
        </p>
      </div>
      {protectionsData.map((section, index) => (
        <div className="section" key={index}>
          <div className="header">
            <div className="title">
              <i className="fas fa-shield-alt"></i>
              <span>{section.title}</span>
            </div>
          </div>
          <div className="items">
            {section.items.map((item, idx) => (
              <Item
                key={idx}
                name={item.name}
                tooltip={item.tooltip}
                checked={
                  checkedItems[item.name] || item.defaultChecked || false
                }
                disabled={item.disabled}
                defaultInfo={item.defaultInfo}
                handlingType={
                  handlingTypes[item.name] || item.handlingType || "Ignore"
                }
                possibleHandlingTypes={item.possibleHandlingTypes || []}
                onToggle={(checked) => handleToggle(item.name, checked)}
                onHandlingTypeChange={handleHandlingTypeChange}
              />
            ))}
          </div>
        </div>
      ))}
      <div className="form-buttons">
        <button type="button" className="save-button" onClick={handleSave}>
          Save
        </button>
        <button type="button" className="next-button" onClick={handleNextClick}>
          Next Step
        </button>
      </div>
    </main>
  );
};

export default Protections;
