import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "../css/sidebar.css";

import icon from "../assets/logo.png";

const setTheme = (themeName) => {
  localStorage.setItem("theme", themeName);
  document.documentElement.className = themeName;
  console.log("Theme set to: ", document.documentElement.className);
};

const toggleTheme = () => {
  if (localStorage.getItem("theme") === "theme-dark") {
    setTheme("theme-light");
  } else {
    setTheme("theme-dark");
  }
};

const getInitialDarkMode = () => {
  return localStorage.getItem("theme") === "theme-dark";
};

(function () {
  if (localStorage.getItem("theme") === "theme-dark") {
    setTheme("theme-dark");
  } else {
    setTheme("theme-light");
  }
})();

const Sidebar = () => {
  const [isDarkMode, setDarkMode] = useState(getInitialDarkMode());

  useEffect(() => {
    const switchItem = document.querySelector(".switch");
    if (isDarkMode) {
      console.log("Dark mode is enabled");
      setTheme("theme-dark");
      switchItem.classList.add("active");
      document.querySelector(".mode-text").textContent = "Light Mode";
    } else {
      console.log("Dark mode is disabled");
      setTheme("theme-light");
      switchItem.classList.remove("active");
      document.querySelector(".mode-text").textContent = "Dark Mode";
    }
  }, [isDarkMode]);

  const handleToggle = () => {
    setDarkMode(!isDarkMode);
    toggleTheme();
  };

  return (
    <nav className="sidebar">
      <header>
        <div className="image-text">
          <span className="image">
            <img src={icon} alt="Logo" />
          </span>
          <div className="text logo-text">
            <span className="name">Skyforce</span>
            <span className="profession">Dashboard</span>
          </div>
        </div>
      </header>
      <div className="menu-bar">
        <div className="menu">
          <ul className="menu-links">
            <li className="nav-link">
              <Link to="/dashboard" id="dashboard-link">
                <i className="bx bx-home-alt icon"></i>
                <span className="text nav-text">Dashboard</span>
              </Link>
            </li>
            <li className="nav-link">
              <Link to="/builder/upload" id="builder-link">
                <i className="bx bxs-cylinder icon"></i>
                <span className="text nav-text">Builder</span>
              </Link>
            </li>
            <li className="nav-link">
              <Link to="/history">
                <i className="bx bx-history icon"></i>
                <span className="text nav-text">Build History</span>
              </Link>
            </li>
            <li className="nav-link">
              <Link to="/analytics">
                <i className="bx bx-pie-chart-alt icon"></i>
                <span className="text nav-text">Analytics</span>
              </Link>
            </li>
            <li className="nav-link">
              <Link to="/detections">
                <i className="bx bx-pie-chart-alt icon"></i>
                <span className="text nav-text">Detections</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="bottom-content">
          <li className="">
            <a href="/login" id="logoutButton">
              <i className="bx bx-log-out icon"></i>
              <span className="text nav-text">Logout</span>
            </a>
          </li>
          <li className="mode">
            <div className="sun-moon">
              <i className="bx bx-moon icon moon"></i>
              <i className="bx bx-sun icon sun"></i>
            </div>
            <span className="mode-text text">
              {isDarkMode ? "Light Mode" : "Dark Mode"}
            </span>
            <div className="toggle-switch" onClick={handleToggle}>
              <span className="switch"></span>
            </div>
          </li>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
