import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import "../css/register.css";

const Register = () => {
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [country, setCountry] = useState("");
  const [captcha, setCaptcha] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleRegisterClick = async () => {
    if (
      email &&
      fullName &&
      companyName &&
      password &&
      confirmPassword &&
      country &&
      captcha &&
      password === confirmPassword
    ) {
      setError("");

      try {
        const url =
          process.env.NODE_ENV === "production"
            ? "https://dashboardapi.skyforceanticheat.com"
            : "http://localhost:3001";
        const response = await axios.post(url + "/auth/register", {
          email,
          name: fullName,
          password,
          company: companyName,
          country,
        });

        if (response.status === 200) {
          navigate("/verifyemail");
        } else {
          setError("Registration failed. Please try again.");
        }
      } catch (error) {
        setError("Registration failed. Please try again.");
      }
    } else {
      setError(
        "Please fill in all required fields with a valid work email and ensure passwords match.",
      );
    }
  };

  const onCaptchaChange = (value) => {
    setCaptcha(true);
  };

  return (
    <div className="register-page">
      <main className="register-container">
        <div className="register-header">
          <div className="form-icon">
            <i className="fas fa-user-plus"></i>
          </div>
          <h2 className="form-title">Register</h2>
          <p className="form-description">Create your account</p>
        </div>
        <form>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="email">Work Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <small className="form-hint">Enter your work email address</small>
            </div>
            <div className="form-group">
              <label htmlFor="full-name">Full Name</label>
              <input
                type="text"
                id="full-name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
              <small className="form-hint">
                Enter your first and last name
              </small>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <small className="form-hint">Enter a secure password</small>
            </div>
            <div className="form-group">
              <label htmlFor="confirm-password">Confirm Password</label>
              <input
                type="password"
                id="confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <small className="form-hint">Re-enter your password</small>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="company-name">Company Name</label>
              <input
                type="text"
                id="company-name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                required
              />
              <small className="form-hint">Enter your company name</small>
            </div>
            <div className="form-group">
              <label htmlFor="country">Country</label>
              <input
                type="text"
                id="country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                required
              />
              <small className="form-hint">
                Enter your companies registration country
              </small>
            </div>
          </div>
          <div className="form-group captcha-container">
            <ReCAPTCHA
              sitekey="6Lff_d8oAAAAAJvO0kozSGnQwVXjvLdBEVMuccdw"
              onChange={onCaptchaChange}
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <div className="form-buttons">
            <button
              type="button"
              className="next-button"
              onClick={handleRegisterClick}
            >
              Sign Up
            </button>
          </div>
          <p className="terms-text">
            By signing up, you agree to our{" "}
            <Link to="/terms" className="terms-link">
              terms of service
            </Link>
            .
          </p>
        </form>
        <Link to="/login" className="login-link">
          Already have an account? Login
        </Link>
      </main>
    </div>
  );
};

export default Register;
