import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { createRoot } from "react-dom/client";

import "./css/style.css";

import Sidebar from "./components/sidebar.js";

import Dashboard from "./pages/dashboard.js";
import Builder from "./pages/builder.js";
import Detections from "./pages/detections.js";
import History from "./pages/history.js";
import Analytics from "./pages/analytics.js";

import Login from "./pages/login.js";
import Register from "./pages/register.js";
import ConfirmEmail from "./pages/confirmemail.js";
import ConfirmEmailToken from "./pages/confirmtoken.js";
import ForgotPassword from "./pages/forgotpassword";
import PasswordReset from "./pages/passwordreset";

class App extends React.Component {
  componentDidMount() {
    const darkModeSetting = localStorage.getItem("darkMode");
    if (darkModeSetting === "enabled") {
      document.documentElement.classList.add("dark");
    }
  }

  render() {
    return (
      <BrowserRouter>
        <MainLayout />
      </BrowserRouter>
    );
  }
}

const MainLayout = () => {
  const location = useLocation();

  const showSidebar =
    !location.pathname.includes("builder") &&
    !(
      location.pathname.includes("login") ||
      location.pathname.includes("register") ||
      location.pathname.includes("email") ||
      location.pathname.includes("password") ||
      location.pathname === "/"
    );

  return (
    <div className="app-container">
      {showSidebar && <Sidebar />}
      {showSidebar ? (
        <div className="main-content">
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/detections" element={<Detections />} />
            <Route path="/history" element={<History />} />
            <Route path="/analytics" element={<Analytics />} />
          </Routes>
        </div>
      ) : (
        <div className="login-main-content">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/verifyemail" element={<ConfirmEmail />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<PasswordReset />} />
            <Route path="/email/confirm" element={<ConfirmEmailToken />} />

            <Route path="/builder/*" element={<Builder />} />
          </Routes>
        </div>
      )}
    </div>
  );
};

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(<App />);
