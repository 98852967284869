import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "../css/passwordreset.css";

const PasswordReset = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("id");

  const handlePasswordResetClick = async () => {
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    if (password) {
      setError("");
      setMessage("");

      try {
        const url =
          process.env.NODE_ENV === "production"
            ? "https://dashboardapi.skyforceanticheat.com"
            : "http://localhost:3001";
        const response = await axios.post(url + "/auth/password-reset", {
          token,
          password,
        });

        if (response.status === 200) {
          setMessage("Password reset successfully. Redirecting to login...");
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          setError("Failed to reset password. Please try again.");
        }
      } catch (error) {
        console.error("Error resetting password:", error);
        setError("An error occurred. Please try again.");
      }
    } else {
      setError("Please enter a new password.");
    }
  };

  return (
    <div className="password-reset-page">
      <main className="password-reset-container">
        <div className="password-reset-header">
          <div className="form-icon">
            <i className="fas fa-unlock-alt"></i>
          </div>
          <h2 className="form-title">Reset Password</h2>
          <p className="form-description">
            Enter a new password for your account
          </p>
        </div>
        <form>
          <div className="form-group">
            <label htmlFor="password">New Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirm-password">Confirm Password</label>
            <input
              type="password"
              id="confirm-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          {message && <p className="message">{message}</p>}
          <div className="form-buttons">
            <button
              type="button"
              className="reset-password-button"
              onClick={handlePasswordResetClick}
            >
              Reset Password
            </button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default PasswordReset;
