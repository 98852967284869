import React from "react";

const Analytics = () => {
  return (
    <div className="page-content">
      <p>Coming soon.</p>
    </div>
  );
};

export default Analytics;
