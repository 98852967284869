import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "../../css/builder/processing.css";

const Processing = ({ onNext }) => {
  const [progress, setProgress] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const uniqueId = params.get("uniqueId");

  function handleNextClick() {
    onNext();
    navigate(`/builder/sign?uniqueId=${uniqueId}`);
  }

  useEffect(() => {
    const url =
      process.env.NODE_ENV === "production"
        ? "https://dashboardapi.skyforceanticheat.com"
        : "http://localhost:3001";
    const interval = setInterval(() => {
      axios
        .get(url + `/api/builder/progress?uniqueId=${uniqueId}`)
        .then((response) => {
          console.log("Progress response:", response.data);
          setProgress(response.data.progress);
          if (response.data.progress >= 100) {
            clearInterval(interval);
          }
        })
        .catch((error) => {
          console.error("Error fetching progress:", error);
        });
    }, 1000);

    return () => clearInterval(interval);
  }, [uniqueId]);

  return (
    <main className="processing-container">
      <div className="processing-header">
        <div className="form-icon">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
        <h2 className="form-title">Processing</h2>
        <p className="form-description">
          We're adding your selections to your app
        </p>
      </div>
      <div className="progress-wrapper">
        <div className="progress-bar-container">
          <div className="progress-bar" style={{ width: `${progress}%` }}></div>
        </div>
        <div className="progress-text">{`${progress}%`}</div>
      </div>
      {progress >= 100 && (
        <div className="form-buttons">
          <button
            type="button"
            className="next-button"
            onClick={handleNextClick}
          >
            Next Step
          </button>
        </div>
      )}
    </main>
  );
};

export default Processing;
