import React, { useEffect, useState } from "react";
import "../../css/builder/item.css";

const Item = ({
  name,
  tooltip,
  checked,
  disabled,
  defaultInfo,
  onToggle,
  possibleHandlingTypes,
  handlingType,
  onHandlingTypeChange,
}) => {
  const [isChecked, setIsChecked] = useState(checked);
  const [selectedHandlingType, setSelectedHandlingType] =
    useState(handlingType);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  useEffect(() => {
    setSelectedHandlingType(handlingType);
  }, [handlingType]);

  const handleChange = (event) => {
    const { checked } = event.target;
    setIsChecked(checked);
    onToggle(checked);
  };

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedHandlingType(value);
    onHandlingTypeChange(name, value);
  };

  return (
    <div className={`item ${disabled ? "disabled-item" : ""}`}>
      <div className="text-with-icon">
        {name}
        {tooltip && (
          <div className="tooltip">
            <i className="fas fa-info-circle"></i>
            <span className="tooltiptext">{tooltip}</span>
          </div>
        )}
      </div>
      <div className="default-info-container">
        <select
          className="select-handling-type"
          value={selectedHandlingType}
          onChange={handleSelectChange}
          disabled={disabled}
        >
          {possibleHandlingTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
        {defaultInfo && (
          <div className="tooltip default-info">
            <i className="fas fa-info-circle"></i>
            <span className="tooltiptext">{defaultInfo}</span>
          </div>
        )}
        <label className="switch">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleChange}
            disabled={disabled}
          />
          <span className="slider round"></span>
        </label>
      </div>
    </div>
  );
};

export default Item;
