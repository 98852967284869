import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/history.css";

const itemsPerPage = 5;

const History = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [buildData, setBuildData] = useState([]);

  useEffect(() => {
    const fetchBuildData = async () => {
      try {
        const url =
          process.env.NODE_ENV === "production"
            ? "https://dashboardapi.skyforceanticheat.com"
            : "http://localhost:3001";
        const response = await axios.get(url + "/api/builds/history", {
          withCredentials: true,
        });
        setBuildData(response.data);
      } catch (error) {
        console.error("Error fetching build history:", error);
      }
    };

    fetchBuildData();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = buildData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(buildData.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const url =
    process.env.NODE_ENV === "production"
      ? "https://dashboardapi.skyforceanticheat.com"
      : "http://localhost:3001";

  return (
    <div className="page-content">
      <section className="history" id="history-section">
        <div className="reports-container">
          <h2 className="recent-history-header">Build History</h2>
          <div className="overflow-x-auto">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>BUILD DATE</th>
                  <th>PACKAGE</th>
                  <th>INFO</th>
                  <th>DOWNLOAD LINK</th>
                  <th>SIGNING</th>
                </tr>
              </thead>
              <tbody id="reports-table-body">
                {currentItems.map((build, index) => (
                  <tr key={index}>
                    <td>{build.UniqueID}</td>
                    <td>{new Date(build.BuildDate).toLocaleString()}</td>
                    <td>{build.PackageName}</td>
                    <td>
                      Skyforce Builder Version: 1.2.0
                      <br />
                      Skyforce Core Version:
                      3231f580bc1fc7e852d1c4472a27517b67fcd661
                    </td>
                    <td>
                      <a
                        href={
                          url +
                          `/api/builder/download?uniqueId=${build.UniqueID}`
                        }
                        className="app-link"
                      >
                        App Download
                      </a>
                    </td>
                    <td>{build.SigningType}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="controls">
          <button onClick={handlePrevPage} disabled={currentPage === 1}>
            Previous Page
          </button>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next Page
          </button>
        </div>
      </section>
    </div>
  );
};

export default History;
