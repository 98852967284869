import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import "../../css/builder/sign.css";

const Sign = ({ onNext }) => {
  const [signingMethod, setSigningMethod] = useState(1); // 1 for On Website, 2 for Self Signing
  const [keystoreFile, setKeystoreFile] = useState(null);
  const [keystorePassword, setKeystorePassword] = useState("");
  const [keyAlias, setKeyAlias] = useState("");
  const [keyPassword, setKeyPassword] = useState("");
  const [error, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const uniqueId = params.get("uniqueId");

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      setKeystoreFile(event.target.files[0]);
      setError("");
    }
  };

  const handleNextClick = async () => {
    if (
      signingMethod === 1 &&
      (!keystoreFile || !keystorePassword || !keyAlias || !keyPassword)
    ) {
      setError("Please fill in all required fields.");
    } else {
      setError("");
      if (signingMethod === 1) {
        setIsProcessing(true);

        const formData = new FormData();
        formData.append("uniqueId", uniqueId);
        formData.append("keystoreFile", keystoreFile);
        formData.append("keystorePassword", keystorePassword);
        formData.append("keyAlias", keyAlias);
        formData.append("keyPassword", keyPassword);

        try {
          const url =
            process.env.NODE_ENV === "production"
              ? "https://dashboardapi.skyforceanticheat.com"
              : "http://localhost:3001";
          const response = await axios.post(
            url + "/api/builder/sign",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          );

          if (response.data.success) {
            onNext();
            navigate(`/builder/download?uniqueId=${uniqueId}`);
          } else {
            setError(response.data.message);
          }
        } catch (error) {
          setError("Failed to sign the APK. Please verify alias and password.");
          console.error("Error signing APK:", error);
        } finally {
          setIsProcessing(false);
        }
      } else {
        // Self Signing, just navigate to the next page
        onNext();
        navigate(`/builder/download?uniqueId=${uniqueId}`);
      }
    }
  };

  return (
    <div className="sign-page">
      <main className="sign-container">
        <div className="sign-header">
          <div className="form-icon">
            <i className="fas fa-pencil-alt"></i>
          </div>
          <h2 className="form-title">Sign</h2>
          <p className="form-description">
            How would you like to sign your app?
          </p>
        </div>
        <form>
          <div className="form-group">
            <label htmlFor="signing-method">How Would You Like to Sign?</label>
            <select
              id="signing-method"
              value={signingMethod}
              onChange={(e) => setSigningMethod(parseInt(e.target.value, 10))}
            >
              <option value={1}>On Website</option>
              <option value={2}>Self signing</option>
            </select>
          </div>
          {signingMethod === 1 && (
            <>
              <div className="form-group">
                <label htmlFor="keystore-file">Keystore</label>
                <div
                  className="upload-area"
                  onClick={() =>
                    document.getElementById("keystore-upload").click()
                  }
                >
                  <div className="upload-text">
                    {keystoreFile ? keystoreFile.name : "Upload Keystore"}
                  </div>
                  <input
                    type="file"
                    id="keystore-upload"
                    accept=".keystore,.jks"
                    onChange={handleFileChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="keystore-password">Keystore Password</label>
                <input
                  type="password"
                  id="keystore-password"
                  value={keystorePassword}
                  onChange={(e) => setKeystorePassword(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="key-alias">Key Alias</label>
                <input
                  type="text"
                  id="key-alias"
                  value={keyAlias}
                  onChange={(e) => setKeyAlias(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="key-password">Key Password</label>
                <input
                  type="password"
                  id="key-password"
                  value={keyPassword}
                  onChange={(e) => setKeyPassword(e.target.value)}
                  required
                />
              </div>
            </>
          )}
          {error && <p className="error-message">{error}</p>}
          <div className="form-buttons">
            <button
              type="button"
              className="next-button"
              onClick={handleNextClick}
              disabled={isProcessing}
            >
              {isProcessing ? "Processing..." : "Next Step"}
            </button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default Sign;
