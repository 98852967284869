import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import "../../css/builder/download.css";

const Download = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const uniqueId = params.get("uniqueId");

  const handleDownload = async () => {
    try {
      const url =
        process.env.NODE_ENV === "production"
          ? "https://dashboardapi.skyforceanticheat.com"
          : "http://localhost:3001";
      const response = await axios.get(url + `/api/builder/download`, {
        params: { uniqueId },
        responseType: "blob", // Important for file downloads
      });

      // Create a URL for the downloaded file
      const url2 = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url2;

      const timestamp = Math.floor(Date.now() / 1000);
      link.setAttribute("download", "processed-" + timestamp + ".apk");

      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading the file:", error);
      alert("Failed to download the file. Please try again.");
    }
  };

  const handleFinish = () => {
    navigate("/history");
  };

  const url =
    process.env.NODE_ENV === "production"
      ? "https://dashboardapi.skyforceanticheat.com"
      : "http://localhost:3001";

  return (
    <div className="download-page">
      <main className="download-container">
        <div className="download-header">
          <div className="form-icon">
            <i className="fas fa-download"></i>
          </div>
          <h2 className="form-title">Download</h2>
          <p className="form-description">
            The app has been built successfully.
          </p>
        </div>
        <div className="form-buttons">
          <a
            className="download-button"
            href={url + `/api/builder/download?uniqueId=${uniqueId}`}
          >
            Download
          </a>
          <button className="finish-button" onClick={handleFinish}>
            Finish
          </button>
        </div>
      </main>
    </div>
  );
};

export default Download;
