import React from "react";
import { Link } from "react-router-dom";

import "../css/confirmemail.css";

const ConfirmEmail = () => {
  return (
    <div className="confirm-email-page">
      <main className="confirm-email-container">
        <div className="confirm-email-header">
          <div className="form-icon">
            <i className="fas fa-envelope"></i>
          </div>
          <h2 className="form-title">State your Intentions</h2>
          {/*
            <h2 className="form-title">Confirm Your Email</h2>
          <p className="form-description">
            We have sent a confirmation email to your inbox. Please verify your
            email address to proceed.
          </p>
          */}
          <p className="form-description">
            We have received your registration, Please contact us and state your
            intentions to use the demo.
          </p>
        </div>
        <div className="form-buttons">
          <Link to="/login" className="back-to-login">
            Back to Login
          </Link>
        </div>
      </main>
    </div>
  );
};

export default ConfirmEmail;
