import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";

import "../css/confirmtoken.css";

const ConfirmEmailToken = () => {
  const [status, setStatus] = useState("loading");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const url =
          process.env.NODE_ENV === "production"
            ? "https://dashboardapi.skyforceanticheat.com"
            : "http://localhost:3001";
        const id = searchParams.get("id");
        const response = await axios.get(url + `/auth/emailconfirm?id=${id}`);
        if (response.status === 200) {
          if (response.data.message === "Account already activated") {
            setStatus("already-activated");
          } else {
            setStatus("success");
          }
        } else {
          setStatus("error");
        }
      } catch (error) {
        setStatus("error");
      }
    };

    confirmEmail();
  }, [searchParams]);

  return (
    <div className="confirmemail-page">
      <main className="confirmemail-container">
        <div className="confirmemail-header">
          <div className="form-icon">
            <i className="fas fa-envelope-open-text"></i>
          </div>
          <h2 className="form-title">Email Confirmation</h2>
          {status === "loading" && (
            <p className="form-description">Loading...</p>
          )}
          {status === "success" && (
            <>
              <p className="form-description">
                Your email has been successfully verified.
              </p>
              <Link to="/login" className="login-button">
                Login
              </Link>
            </>
          )}
          {status === "already-activated" && (
            <>
              <p className="form-description">
                Your account has already been activated.
              </p>
              <Link to="/login" className="login-button">
                Login
              </Link>
            </>
          )}
          {status === "error" && (
            <>
              <p className="form-description">
                Email verification failed. Please try again.
              </p>
            </>
          )}
        </div>
      </main>
    </div>
  );
};

export default ConfirmEmailToken;
