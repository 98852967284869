import React, { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import "../css/builder.css";
import BuilderSidebar from "./builder/sidebar";
import Upload from "./builder/upload";
import Protections from "./builder/protections";
import Extras from "./builder/extras";
import Processing from "./builder/processing";
import Sign from "./builder/sign";
import Download from "./builder/download";

const Builder = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();

  const handleNextStep = () => {
    if (currentStep < 5) {
      setCurrentStep(currentStep + 1);
      navigate(stepToPath(currentStep + 1));
    }
  };

  const handleNextStepNoRedirect = () => {
    if (currentStep < 5) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleStepClick = (step) => {
    if (step <= currentStep) {
      navigate(stepToPath(step));
    }
  };

  const stepToPath = (step) => {
    switch (step) {
      case 1:
        return "/builder/upload";
      case 2:
        return "/builder/protections";
      case 3:
        return "/builder/extras";
      case 4:
        return "/builder/processing";
      case 5:
        return "/builder/sign";
      case 6:
        return "/builder/download";
      default:
        return "/builder/upload";
    }
  };

  return (
    <div className="builder-container">
      <BuilderSidebar currentStep={currentStep} onStepClick={handleStepClick} />
      <div className="builder-content">
        <Routes>
          <Route
            path="upload"
            element={<Upload onNext={handleNextStepNoRedirect} />}
          />
          <Route
            path="protections"
            element={<Protections onNext={handleNextStepNoRedirect} />}
          />
          <Route
            path="extras"
            element={<Extras onNext={handleNextStepNoRedirect} />}
          />
          <Route
            path="processing"
            element={<Processing onNext={handleNextStepNoRedirect} />}
          />
          <Route
            path="sign"
            element={<Sign onNext={handleNextStepNoRedirect} />}
          />
          <Route path="download" element={<Download />} />
        </Routes>
      </div>
    </div>
  );
};

export default Builder;
