import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "../css/forgotpassword.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleForgotPasswordClick = async () => {
    if (email) {
      setError("");
      setMessage("");

      try {
        const url =
          process.env.NODE_ENV === "production"
            ? "https://dashboardapi.skyforceanticheat.com"
            : "http://localhost:3001";
        const response = await axios.post(url + "/auth/forgot-password", {
          email,
        });

        if (response.status === 200) {
          setMessage("Password reset link sent. Please check your email.");
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          setError("Failed to send password reset link. Please try again.");
        }
      } catch (error) {
        console.error("Error sending password reset link:", error);
        setError("An error occurred. Please try again.");
      }
    } else {
      setError("Please enter your email.");
    }
  };

  return (
    <div className="forgot-password-page">
      <main className="forgot-password-container">
        <div className="forgot-password-header">
          <div className="form-icon">
            <i className="fas fa-unlock-alt"></i>
          </div>
          <h2 className="form-title">Forgot Password</h2>
          <p className="form-description">
            Enter your email to receive a password reset link
          </p>
        </div>
        <form>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          {message && <p className="message">{message}</p>}
          <div className="form-buttons">
            <button
              type="button"
              className="forgot-password-button"
              onClick={handleForgotPasswordClick}
            >
              Send Reset Link
            </button>
          </div>
        </form>
        <div className="back-to-login-link">
          <Link to="/login">Back to Login</Link>
        </div>
      </main>
    </div>
  );
};

export default ForgotPassword;
